<div class="page-wrapper">
    <div class="page-header">
        <!-- <img src="../assets/extension-icon.png" *ngIf="currentPage === 'login'" class="page-header--icon" (click)="redirectToWebsite()"/> -->
        <div *ngIf="currentPage === 'login'" style="cursor: initial;" class="page-header--icon"></div>
        <div class="button page-header--menu" [ngClass]="{'page-header--menu-active': menuTabActive}" 
            *ngIf="
                currentPage === 'calendar' || 
                currentPage === 'integrations' || 
                currentPage === 'social-pages' || 
                currentPage === 'payments' || 
                currentPage === 'settings'
            "
            (click)="toggleMenuTab()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title>Menu</title><path stroke-linecap="round" stroke-miterlimit="10" stroke-width="48" d="M88 152h336M88 256h336M88 360h336"/></svg>
        </div>
        <div *ngIf="currentPage === 'calendar-day'" (click)="currentPage = 'calendar'" class="button page-header--menu">
            <!-- <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 16.6667L6.66668 10.0001L13.3333 3.33342" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.3333 16.6667L6.66668 10.0001L13.3333 3.33342" fill="none" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->
            <svg width="16" height="16" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6.99991H3.83005L8.71005 2.11991C9.10005 1.72991 9.10005 1.08991 8.71005 0.699914C8.61753 0.607211 8.50764 0.533663 8.38667 0.483482C8.2657 0.4333 8.13602 0.407471 8.00505 0.407471C7.87408 0.407471 7.7444 0.4333 7.62342 0.483482C7.50245 0.533663 7.39256 0.607211 7.30005 0.699914L0.710047 7.28991C0.617343 7.38243 0.543796 7.49232 0.493614 7.61329C0.443433 7.73426 0.417603 7.86395 0.417603 7.99491C0.417603 8.12588 0.443433 8.25557 0.493614 8.37654C0.543796 8.49751 0.617343 8.6074 0.710047 8.69991L7.30005 15.2899C7.39263 15.3825 7.50254 15.4559 7.6235 15.506C7.74447 15.5561 7.87412 15.5819 8.00505 15.5819C8.13598 15.5819 8.26563 15.5561 8.38659 15.506C8.50755 15.4559 8.61746 15.3825 8.71005 15.2899C8.80263 15.1973 8.87607 15.0874 8.92617 14.9665C8.97628 14.8455 9.00207 14.7158 9.00207 14.5849C9.00207 14.454 8.97628 14.3243 8.92617 14.2034C8.87607 14.0824 8.80263 13.9725 8.71005 13.8799L3.83005 8.99991H15C15.55 8.99991 16 8.54991 16 7.99991C16 7.44991 15.55 6.99991 15 6.99991Z" stroke="none"/>
            </svg>
        </div>
        <div *ngIf="currentPage === 'schedule-post'" (click)="currentPage = 'calendar-day'" class="button page-header--menu">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6.99991H3.83005L8.71005 2.11991C9.10005 1.72991 9.10005 1.08991 8.71005 0.699914C8.61753 0.607211 8.50764 0.533663 8.38667 0.483482C8.2657 0.4333 8.13602 0.407471 8.00505 0.407471C7.87408 0.407471 7.7444 0.4333 7.62342 0.483482C7.50245 0.533663 7.39256 0.607211 7.30005 0.699914L0.710047 7.28991C0.617343 7.38243 0.543796 7.49232 0.493614 7.61329C0.443433 7.73426 0.417603 7.86395 0.417603 7.99491C0.417603 8.12588 0.443433 8.25557 0.493614 8.37654C0.543796 8.49751 0.617343 8.6074 0.710047 8.69991L7.30005 15.2899C7.39263 15.3825 7.50254 15.4559 7.6235 15.506C7.74447 15.5561 7.87412 15.5819 8.00505 15.5819C8.13598 15.5819 8.26563 15.5561 8.38659 15.506C8.50755 15.4559 8.61746 15.3825 8.71005 15.2899C8.80263 15.1973 8.87607 15.0874 8.92617 14.9665C8.97628 14.8455 9.00207 14.7158 9.00207 14.5849C9.00207 14.454 8.97628 14.3243 8.92617 14.2034C8.87607 14.0824 8.80263 13.9725 8.71005 13.8799L3.83005 8.99991H15C15.55 8.99991 16 8.54991 16 7.99991C16 7.44991 15.55 6.99991 15 6.99991Z" stroke="none"/>
            </svg>
        </div>
        <div *ngIf="currentPage === 'schedule-post-preview'" (click)="currentPage = 'schedule-post'" class="button page-header--menu">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 6.99991H3.83005L8.71005 2.11991C9.10005 1.72991 9.10005 1.08991 8.71005 0.699914C8.61753 0.607211 8.50764 0.533663 8.38667 0.483482C8.2657 0.4333 8.13602 0.407471 8.00505 0.407471C7.87408 0.407471 7.7444 0.4333 7.62342 0.483482C7.50245 0.533663 7.39256 0.607211 7.30005 0.699914L0.710047 7.28991C0.617343 7.38243 0.543796 7.49232 0.493614 7.61329C0.443433 7.73426 0.417603 7.86395 0.417603 7.99491C0.417603 8.12588 0.443433 8.25557 0.493614 8.37654C0.543796 8.49751 0.617343 8.6074 0.710047 8.69991L7.30005 15.2899C7.39263 15.3825 7.50254 15.4559 7.6235 15.506C7.74447 15.5561 7.87412 15.5819 8.00505 15.5819C8.13598 15.5819 8.26563 15.5561 8.38659 15.506C8.50755 15.4559 8.61746 15.3825 8.71005 15.2899C8.80263 15.1973 8.87607 15.0874 8.92617 14.9665C8.97628 14.8455 9.00207 14.7158 9.00207 14.5849C9.00207 14.454 8.97628 14.3243 8.92617 14.2034C8.87607 14.0824 8.80263 13.9725 8.71005 13.8799L3.83005 8.99991H15C15.55 8.99991 16 8.54991 16 7.99991C16 7.44991 15.55 6.99991 15 6.99991Z" stroke="none"/>
            </svg>
        </div>

        <div class="page-header--title" [ngClass]="{'page-header--title-small': currentPage !== 'login'}">
            <ng-container *ngIf="menuTabActive || notificationsTabActive">
                <ng-container *ngIf="menuTabActive">
                    <span class="page-header--title-contrast">Menu</span>
                </ng-container>
                <ng-container *ngIf="notificationsTabActive">
                    <span class="page-header--title-contrast">Notifiche</span>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!menuTabActive && !notificationsTabActive">
                <ng-container *ngIf="currentPage === 'login'">
                    io<span class="page-header--title-contrast">posto</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'calendar'">
                    <span class="page-header--title-contrast">Calendario Editoriale</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'calendar-day'">
                    <span class="page-header--title-contrast">Lista post</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'schedule-post'">
                    <span class="page-header--title-contrast">Programma post</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'schedule-post-preview'">
                    <span class="page-header--title-contrast">Anteprima post</span>
                </ng-container>
    
                <ng-container *ngIf="currentPage === 'integrations'">
                    <span class="page-header--title-contrast">Plugin</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'social-pages'">
                    <span class="page-header--title-contrast">Pagine Social</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'payments'">
                    <span class="page-header--title-contrast">Pagamenti</span>
                </ng-container>
                <ng-container *ngIf="currentPage === 'settings'">
                    <span class="page-header--title-contrast">Impostazioni</span>
                </ng-container>
            </ng-container>
            
        </div>
        
        <div class="page-header--menu" style="cursor: initial;" *ngIf="currentPage === 'login' || currentPage === 'schedule-post-preview'"></div>
        <div class="button page-header--menu" [ngClass]="{'page-header--menu-active': notificationsTabActive}" *ngIf="
            currentPage === 'calendar' || 
            currentPage === 'integrations' || 
            currentPage === 'social-pages' || 
            currentPage === 'payments' || 
            currentPage === 'settings'
        " (click)="toggleNotificationsTab()">
            <svg style="padding: 2px;" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                <path d="M32.85 28.13L32.51 27.83C31.5454 26.9706 30.7011 25.985 30 24.9C29.2342 23.4025 28.7752 21.7672 28.65 20.09V15.15C28.6566 12.5156 27.701 9.96947 25.9627 7.98997C24.2244 6.01046 21.8231 4.73385 19.21 4.39999V3.10999C19.21 2.75593 19.0693 2.41637 18.819 2.16601C18.5686 1.91565 18.2291 1.77499 17.875 1.77499C17.5209 1.77499 17.1814 1.91565 16.931 2.16601C16.6806 2.41637 16.54 2.75593 16.54 3.10999V4.41999C13.9502 4.77792 11.578 6.06225 9.86252 8.0351C8.14707 10.008 7.20472 12.5356 7.20999 15.15V20.09C7.08474 21.7672 6.62577 23.4025 5.85999 24.9C5.17087 25.9823 4.34018 26.9677 3.38999 27.83L3.04999 28.13V30.95H32.85V28.13Z"/>
                <path d="M15.32 32C15.4077 32.6339 15.7218 33.2146 16.2043 33.635C16.6868 34.0553 17.3051 34.2869 17.945 34.2869C18.5849 34.2869 19.2032 34.0553 19.6857 33.635C20.1682 33.2146 20.4823 32.6339 20.57 32H15.32Z"/>
            </svg>
        </div>
        <div *ngIf="currentPage === 'calendar-day'" class="button page-header--menu">
            <svg width="16" height="16" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17 15H2M17 30V15V30ZM17 15V0V15ZM17 15H32H17Z" stroke-width="5" stroke-linecap="round"/>
            </svg>
        </div>
        <div *ngIf="currentPage === 'schedule-post'" (click)="currentPage = 'schedule-post-preview'" class="button page-header--menu">
            <svg width="20" viewBox="0 0 28 15" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 0C5.25202 0 0.666687 6.44267 0.666687 7.46667C0.666687 8.488 5.25202 14.9333 14 14.9333C22.7467 14.9333 27.3334 8.488 27.3334 7.46667C27.3334 6.44267 22.7467 0 14 0ZM14 13.2093C10.7267 13.2093 8.07335 10.6387 8.07335 7.46667C8.07335 4.29467 10.7267 1.72133 14 1.72133C17.2734 1.72133 19.9254 4.29467 19.9254 7.46667C19.9254 10.6387 17.2734 13.2093 14 13.2093ZM14 7.46667C13.4574 6.87067 14.884 4.59467 14 4.59467C12.3627 4.59467 11.036 5.88133 11.036 7.46667C11.036 9.052 12.3627 10.3387 14 10.3387C15.636 10.3387 16.964 9.052 16.964 7.46667C16.964 6.73733 14.4614 7.972 14 7.46667Z"/>
            </svg>
        </div>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" (click)="redirectToWebsite()"><title>Menu</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="48" d="M88 152h336M88 256h336M88 360h336"/></svg> -->
    </div>

    <app-landing (loggedInEmitter)="loginSucceeded($event)" *ngIf="currentPage === 'login'"></app-landing>
    
    <app-calendar *ngIf="currentPage === 'calendar' || currentPage === 'calendar-day'" [lastCalendarDate]="lastCalendarDate" [view]="currentPage === 'calendar-day' ? CalendarViewEnum.Day : CalendarViewEnum.Month" (calendarTypeEmitter)="currentPage = $event" (openPostEmitter)="openPost($event)"></app-calendar>
    
    <app-schedule-post *ngIf="currentPage === 'schedule-post'" [selectedPost]="selectedPost" (openPreviewEmitter)="openPostPreview()" (selectedPostEmitter)="selectedPost = $event;"></app-schedule-post>

    <app-schedule-post-preview *ngIf="currentPage === 'schedule-post-preview'" [selectedPost]="selectedPost" (closePreviewEmitter)="currentPage = 'schedule-post'" (postScheduledEmitter)="closePreviewAndSelectDay($event)"></app-schedule-post-preview>
    
    <app-integrations *ngIf="currentPage === 'integrations'" (openSocialPagesEmitter)="openSocialPages($event)"></app-integrations>
    
    <app-social-pages *ngIf="currentPage === 'social-pages'" [socialPages]="socialPages" (openIntegrationsPageEmitter)="changeActivePage('integrations')"></app-social-pages>
    
    <app-payments *ngIf="currentPage === 'payments'"></app-payments>

    <app-settings *ngIf="currentPage === 'settings'" (loggedOutEmitter)="init()"></app-settings>

    <app-menu [menuTabActive]="menuTabActive" [activePage]="currentPage" (activePageEmitter)="changeActivePage($event)"></app-menu>    
    <app-notifications [notificationsTabActive]="notificationsTabActive"></app-notifications>    
</div>

