import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Output() activePageEmitter = new EventEmitter<string>();
  @Input() menuTabActive: boolean;
  @Input() activePage: string;

  constructor() {
    this.menuTabActive = false;
    this.activePage = '';
  }

  ngOnInit(): void {
  }

  changeActivePage(page: string) {
    if (this.activePage !== page) {
      this.activePageEmitter.emit(page);
    }
  }

}
