<div class="page-content appear-animation" [ngClass]="{'page-content--active': menuTabActive}">
    <div class="menu-item" [ngClass]="{'menu-item--active': activePage === 'calendar'}" (click)="changeActivePage('calendar')">
        Calendario Editoriale
    </div>
    <div class="menu-item" [ngClass]="{'menu-item--active': activePage === 'social-pages'}" (click)="changeActivePage('social-pages')">
        Pagine Social
    </div>
    <div class="menu-item" [ngClass]="{'menu-item--active': activePage === 'integrations'}" (click)="changeActivePage('integrations')">
        Plugin
    </div>
    <div class="menu-item" [ngClass]="{'menu-item--active': activePage === 'payments'}" (click)="changeActivePage('payments')">
        Pagamenti
    </div>
    <div class="menu-item" [ngClass]="{'menu-item--active': activePage === 'settings'}" (click)="changeActivePage('settings')">
        Impostazioni
    </div>

    <div class="page-footer appear-animation">
        <div class="page-footer--help">Serve aiuto? <span class="link">Contattaci</span></div>
    </div>
</div>