import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FbApiService } from '../fb-api.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent {

  @Output() openSocialPagesEmitter = new EventEmitter<any[]>();
  facebookIntegration: any;
  notionIntegration: any;

  fbAccountsVisible: boolean;
  fbCounters: { facebook: number, instagram: number };

  facebookAccessIframeUrl?: string;

  constructor(
    private fbService: FbApiService,
  ) {
    this.fbAccountsVisible = false;
    this.fbCounters = {
      facebook: 0,
      instagram: 0
    };
    this.getFbAccounts().then((_) => {});
  }

  toggleFbAccounts() {
    this.fbAccountsVisible = !this.fbAccountsVisible;
  }

  addIntegration(integrationName: string) {
    if (integrationName === 'facebook') {
      this.requestFacebookAuthorization();
    } else if (integrationName === 'notion') {
    }
  }

  requestFacebookAuthorization() {
    const clientId = 468271111630753;
    const state = { email: 'vladgiurgila97@gmail.com' };
    const redirectUri = 'https://ioposto.com/api/fb/callback';
    const baseUrl = 'https://www.facebook.com/v12.0/dialog/oauth?';
    const locationUrl = `${baseUrl}client_id=${clientId}&redirect_uri=${redirectUri}&state=${encodeURIComponent(JSON.stringify(state))}`;
    // window.open(locationUrl, '_blank');
    this.facebookAccessIframeUrl = locationUrl;

    // setInterval(() => {

    // }, )
    const fbAccessCheckInterval =  setInterval(() => {
      this.getFbAccounts().then((_) => {
        this.facebookAccessIframeUrl = undefined;
        clearInterval(fbAccessCheckInterval);
      });
    }, 2000);
  }

  getFbAccounts() {
    return new Promise((res, rej) => {
      this.fbService.getAccounts('vladgiurgila97@gmail.com').then((accounts) => {
        this.facebookIntegration = accounts;
        this.fbCounters.facebook = this.facebookIntegration.length;
  
        let instagramAccountsCount = 0;
        this.facebookIntegration.forEach((facebookPage: any) => {
          if (facebookPage.instagram?.id) {
            instagramAccountsCount++;
          }
        });
        this.fbCounters.instagram = instagramAccountsCount;
        res(true);
      }, (error) => {
        const errorMsg = error.error;
        if (errorMsg.includes('Missing access code, please authorize facebook app')) {
          this.facebookIntegration = null;
        }
        rej();
      });
    });
  }

  openSocialPages() {
    const socialPages: any[] = [];
    this.facebookIntegration.forEach((facebookPage: any) => {
      if (facebookPage?.instagram?.id) {
        const instagramPage = facebookPage.instagram;
        instagramPage.type = 'instagram';
        socialPages.push(facebookPage.instagram);
        delete facebookPage.instagram;
      }
      facebookPage.type = 'facebook';
      socialPages.push(facebookPage);
    });
    this.openSocialPagesEmitter.emit(socialPages);
  }
}
