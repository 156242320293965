<div class="component-wrapper">

</div>
<div class="page-content appear-animation">
    <div class="page-content--login">
        <img src="../assets/extension-icon.png" class="page-content--login-icon" />
        <div class="page-content--login-title">Bentornato!</div>
        <div class="page-content--login-paragraph">La tua programmazione ti attende</div>
        <button (click)="login()" class="page-content--login-cta button">Connetti</button>
        <div class="page-footer--signup-text">oppure <span class="link">Crea un account</span></div>
    </div>
</div>

<div class="page-footer appear-animation">
    <div class="page-footer--help">Serve aiuto? Contatta il <span class="link">Supporto</span></div>
</div>
