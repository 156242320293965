import { FbApiService, SchedulePostBody } from './../fb-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-schedule-post-preview',
  templateUrl: './schedule-post-preview.component.html',
  styleUrls: ['./schedule-post-preview.component.scss']
})
export class SchedulePostPreviewComponent implements OnInit {
  
  @Input() selectedPost: any;
  @Output() closePreviewEmitter: EventEmitter<boolean>;
  @Output() postScheduledEmitter: EventEmitter<any>;
  scheduleLoaderActive: boolean = false;

  constructor(
    private fbApiService: FbApiService,
  ) {
    this.closePreviewEmitter = new EventEmitter<boolean>();
    this.postScheduledEmitter = new EventEmitter<any>();
  }

  ngOnInit(): void {
    if (this.selectedPost.meta.data.message.length > 100) {
      this.selectedPost.meta.data.shortMessage = this.selectedPost.meta.data.message.substring(0, 100);
      this.selectedPost.meta.data.expandedMessage = false;
    }
  }

  closePreview() {
    this.closePreviewEmitter.emit(true);
  }

  schedulePost() {
    if (this.selectedPost.meta.data.id) {
      alert('Il post è già stato programmato');
      return;
    }
    const postBody: SchedulePostBody = {
      timestamp: this.selectedPost.meta.timestamp,
      data: {
        message: this.selectedPost.meta.data.message,
        mediaUrl: this.selectedPost.meta.data.mediaUrl,
        accountEmail: this.selectedPost.meta.data.accountEmail,
        accountId: this.selectedPost.account.id,
        postType: this.selectedPost.meta.data.postType,
        platform: this.selectedPost.account.type,
      }
    };
    this.scheduleLoaderActive = true;
    this.fbApiService.schedulePost(postBody).then(() => {
      alert('post programmato');
      this.scheduleLoaderActive = false;
      this.postScheduledEmitter.emit(this.selectedPost);
    });
  }

  updatePostSchedule() {
    console.log(this.selectedPost);
  }

}
