<app-user-select [selectedAccount]="selectedPost.account" (selectedAccountEmitter)="updateSelectedAccount($event)" [editable]="!selectedPost.meta.data?.id ? true : false"></app-user-select>

<div class="appear-animation" *ngIf="selectedPost">
    <input type="date" [ngModel]="selectedPost.start | date:'yyyy-MM-dd'" (ngModelChange)="updatePostDate($event)" class="date-button" placeholder="Data e ora"/>
    <input type="time" [ngModel]="selectedPost.start | date:'HH:mm'" (ngModelChange)="updatePostTime($event)" class="time-button" placeholder="Data e ora"/>
</div>

<!-- <div class="post-type appear-animation" *ngIf="selectedPost">
    <div class="post-type--button" [ngClass]="{'post-type--button-active': selectedPost.meta.data.postType === 'photo'}" (click)="changePostType('photo')">
        <div class="post-type--button-text">Photo</div>
        <svg class="post-type--button-icon" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1818 0H1.81818C0.909091 0 0 1 0 2V16C0 17.1 0.818182 18 1.81818 18H18.1818C19.0909 18 20 17 20 16V2C20 1 19.0909 0 18.1818 0ZM18.1818 15.92C18.1636 15.95 18.1273 15.98 18.1091 16H1.81818V2.08L1.89091 2H18.1C18.1273 2.02 18.1545 2.06 18.1727 2.08V15.92H18.1818ZM9.09091 12.51L6.81818 9.5L3.63636 14H16.3636L12.2727 8L9.09091 12.51Z" fill="black" fill-opacity="0.6"/>
        </svg>
    </div>
    <div class="post-type--button" [ngClass]="{'post-type--button-active': selectedPost.meta.data.postType === 'video'}" (click)="changePostType('video')">
        <div class="post-type--button-text">Video</div>
        <svg class="post-type--button-icon" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.11111 0H18.8889C19.5 0 20 0.5 20 1.11111V18.8889L0 18.8667V1.11111C0 0.5 0.5 0 1.11111 0ZM5.55556 1.11111L3.33333 4.44444H4.44444L6.66667 1.11111H5.55556ZM10 1.11111H8.88889L6.66667 4.44444H7.77778L10 1.11111ZM13.3333 1.11111H12.2222L10 4.44444H11.1111L13.3333 1.11111ZM16.6667 1.11111H15.5556L13.3333 4.44444H14.4444L16.6667 1.11111ZM17.7778 16.6667V5.55556H2.22222V16.6667H17.7778ZM7.77778 6.66667L14.4444 11.1111L7.77778 15.5556V6.66667Z" fill="black" fill-opacity="0.6"/>
        </svg>
    </div>
    <div class="post-type--button" [ngClass]="{'post-type--button-active': selectedPost.meta.data.postType === 'link'}" (click)="changePostType('link')">
        <div class="post-type--button-text">Link</div>
        <svg class="post-type--button-icon" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.46195 17.018C1.92583 17.4825 2.47692 17.8508 3.08357 18.1017C3.69022 18.3526 4.34047 18.4811 4.99695 18.48C5.65358 18.4811 6.30397 18.3525 6.91078 18.1016C7.51759 17.8508 8.06886 17.4825 8.53295 17.018L11.361 14.189L9.94695 12.775L7.11895 15.604C6.55542 16.165 5.79262 16.4799 4.99745 16.4799C4.20228 16.4799 3.43948 16.165 2.87595 15.604C2.31446 15.0407 1.99916 14.2778 1.99916 13.4825C1.99916 12.6871 2.31446 11.9242 2.87595 11.361L5.70495 8.53295L4.29095 7.11895L1.46195 9.94695C0.525758 10.8854 0 12.1569 0 13.4825C0 14.808 0.525758 16.0795 1.46195 17.018V17.018ZM17.018 8.53295C17.9537 7.59424 18.4791 6.32287 18.4791 4.99745C18.4791 3.67203 17.9537 2.40067 17.018 1.46195C16.0795 0.525758 14.808 0 13.4825 0C12.1569 0 10.8854 0.525758 9.94695 1.46195L7.11895 4.29095L8.53295 5.70495L11.361 2.87595C11.9245 2.31494 12.6873 1.99998 13.4825 1.99998C14.2776 1.99998 15.0404 2.31494 15.604 2.87595C16.1654 3.43922 16.4807 4.20212 16.4807 4.99745C16.4807 5.79278 16.1654 6.55568 15.604 7.11895L12.775 9.94695L14.189 11.361L17.018 8.53295Z" fill="black" fill-opacity="0.6"/>
        </svg>
    </div>
    <div class="post-type--button" [ngClass]="{'post-type--button-active': selectedPost.meta.data.postType === 'text'}" (click)="changePostType('text')">
        <div class="post-type--button-text">Text</div>
        <svg class="post-type--button-icon" width="27" height="14" style="margin: 3px 0;" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27 14H22C21.4698 13.9992 20.9616 13.7882 20.5867 13.4133C20.2118 13.0384 20.0008 12.5302 20 12V6C20.0005 5.46973 20.2114 4.96133 20.5864 4.58637C20.9613 4.21141 21.4697 4.00053 22 4H27V6H22V12H27V14Z" fill="black" fill-opacity="0.6"/>
            <path d="M16 4H12V0H10V14H16C16.5302 13.9992 17.0384 13.7882 17.4133 13.4133C17.7882 13.0384 17.9992 12.5302 18 12V6C17.9995 5.46973 17.7886 4.96133 17.4136 4.58637C17.0387 4.21141 16.5303 4.00053 16 4V4ZM12 12V6H16V12H12Z" fill="black" fill-opacity="0.6"/>
            <path d="M6 4H1V6H6V8H2C1.46957 8 0.960859 8.21071 0.585786 8.58579C0.210714 8.96086 0 9.46957 0 10V12C0 12.5304 0.210714 13.0391 0.585786 13.4142C0.960859 13.7893 1.46957 14 2 14H8V6C7.99947 5.46973 7.78859 4.96133 7.41363 4.58637C7.03867 4.21141 6.53027 4.00053 6 4V4ZM6 12H2V10H6V12Z" fill="black" fill-opacity="0.6"/>
        </svg>
            
    </div>
</div> -->


<div class="media-container appear-animation" *ngIf="selectedPost" (click)="openFileUploadModal()">
    <div class="loader" *ngIf="fileLoaderActive">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.421 22 22 17.421 22 12H20C20 16.337 16.337 20 12 20C7.663 20 4 16.337 4 12C4 7.664 7.663 4 12 4V2C6.579 2 2 6.58 2 12C2 17.421 6.579 22 12 22Z" fill="black"/>
        </svg>
    </div>
    <div class="media-preview" *ngIf="!fileLoaderActive">
        <img [attr.src]="selectedPost.meta.data?.mediaUrl" *ngIf="selectedPost.meta.data.postType === 'photo'"/>
        <video *ngIf="selectedPost.meta.data.postType === 'video'" playsinline loop autoplay poster="https://download.flaticon.com/download/icon/4131737?icon_id=4131737&author=672&team=672&keyword=Error&pack=4131688&style=Outline+Color&style_id=1225&format=png&color=%23000000&colored=2&size=512&selection=1&premium=0&type=standard&search=error+image">
            <source [attr.src]="selectedPost.meta.data?.mediaUrl"/>
            <img src="https://download.flaticon.com/download/icon/4131737?icon_id=4131737&author=672&team=672&keyword=Error&pack=4131688&style=Outline+Color&style_id=1225&format=png&color=%23000000&colored=2&size=512&selection=1&premium=0&type=standard&search=error+image" title="Your browser does not support the <video> tag">
        </video>
        <svg width="24" height="24" *ngIf="!selectedPost.meta.data?.mediaUrl" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.21 16.83L8.25 14.47L5.5 18H16.5L12.96 13.29L10.21 16.83Z" fill="black" fill-opacity="0.6"/>
            <path opacity="0.3" d="M16.5 18H5.5L8.25 14.47L10.21 16.83L12.96 13.29L16.5 18ZM17 7H14V6H4V20H18V10H17V7Z" fill="white"/>
            <path d="M20 4V1H18V4H15V6H18V8.99H20V6H23V4H20ZM18 20H4V6H14V4H4C2.9 4 2 4.9 2 6V20C2 21.1 2.9 22 4 22H18C19.1 22 20 21.1 20 20V10H18V20Z" fill="black" fill-opacity="0.6"/>
        </svg>
        <input type="file" #fileInput (change)="updateFile($event)" style="display: none;"/>
            
    </div>
    <div *ngIf="!selectedPost.meta.data?.mediaUrl" class="upload-file-text">
        {{!fileLoaderActive ? 'Carica file' : ''}}
        {{fileLoaderActive ? 'Caricamento in corso' : ''}}
    </div>
</div>

<div class="description-container appear-animation" *ngIf="selectedPost">
    <textarea class="description-text" placeholder="Descrizione post" [ngModel]="selectedPost.meta.data.message" (ngModelChange)="selectedPost.meta.data.message = $event; emitSelectedPost()"></textarea>
    <svg (click)="toggleEmojiPopup()" class="description-emoji" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99331 0.333252C3.31331 0.333252 0.333313 3.31992 0.333313 6.99992C0.333313 10.6799 3.31331 13.6666 6.99331 13.6666C10.68 13.6666 13.6666 10.6799 13.6666 6.99992C13.6666 3.31992 10.68 0.333252 6.99331 0.333252ZM4.66665 4.33325C5.21998 4.33325 5.66665 4.77992 5.66665 5.33325C5.66665 5.88659 5.21998 6.33325 4.66665 6.33325C4.11331 6.33325 3.66665 5.88659 3.66665 5.33325C3.66665 4.77992 4.11331 4.33325 4.66665 4.33325ZM6.99998 10.9999C5.47998 10.9999 4.18665 9.89325 3.66665 8.33325H10.3333C9.81331 9.89325 8.51998 10.9999 6.99998 10.9999ZM9.33331 6.33325C8.77998 6.33325 8.33331 5.88659 8.33331 5.33325C8.33331 4.77992 8.77998 4.33325 9.33331 4.33325C9.88665 4.33325 10.3333 4.77992 10.3333 5.33325C10.3333 5.88659 9.88665 6.33325 9.33331 6.33325Z" />
    </svg>
    <div class="emoji-modal" *ngIf="emojiPopupToggled">
        <emoji-mart [darkMode]="false" (emojiClick)="addEmoji($event)" [isNative]="true"></emoji-mart>
    </div>
</div>

<div class="post-actions appear-animation" *ngIf="!emojiPopupToggled">
    <div class="button" (click)="openPreview()">
        <div>Anteprima</div>
        <svg width="18" height="11" viewBox="0 0 18 11" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0C3.0951 0 0 4.3488 0 5.04C0 5.7294 3.0951 10.08 9 10.08C14.904 10.08 18 5.7294 18 5.04C18 4.3488 14.904 0 9 0ZM9 8.9163C6.7905 8.9163 4.9995 7.1811 4.9995 5.04C4.9995 2.8989 6.7905 1.1619 9 1.1619C11.2095 1.1619 12.9996 2.8989 12.9996 5.04C12.9996 7.1811 11.2095 8.9163 9 8.9163ZM9 5.04C8.6337 4.6377 9.5967 3.1014 9 3.1014C7.8948 3.1014 6.9993 3.9699 6.9993 5.04C6.9993 6.1101 7.8948 6.9786 9 6.9786C10.1043 6.9786 11.0007 6.1101 11.0007 5.04C11.0007 4.5477 9.3114 5.3811 9 5.04Z"/>
        </svg>
    </div>
</div>


<!-- <div>{{selectedPost.account.picture}}</div>
<div>{{selectedPost.account.type}}</div>
<div>{{selectedPost.account.name || selectedPost.account.username}}</div>

<div>{{selectedPost.meta.data.mediaUrl}}</div>
<div>{{selectedPost.meta.data.message}}</div>
<div>{{selectedPost.meta.timestamp}}</div>
<div>{{selectedPost.meta.status}}</div> -->