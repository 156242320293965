import { FbApiService } from './../fb-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-social-pages',
  templateUrl: './social-pages.component.html',
  styleUrls: ['./social-pages.component.scss']
})
export class SocialPagesComponent implements OnInit {

  @Output() openIntegrationsPageEmitter = new EventEmitter<null>();
  @Input() socialPages?: any[];
  Highcharts: typeof Highcharts = Highcharts; // required
  chartOptions: Highcharts.Options; // required
  unauthorizedFB: boolean;

  constructor(
    private fbService: FbApiService,
  ) {
    this.unauthorizedFB = false;
    this.chartOptions = {
      chart: {
        type: 'areaspline',
        // plotAreaWidth: 300,
        height: 200,
        // plotAreaHeight: 200

        // borderColor: 'rgba(0,0,0,0.1)',
        // borderWidth: 1,
        // spacingBottom: 0,
        spacingTop: 0,
        spacingLeft: 0,
        spacingRight: 0,
        // marginBottom: 0,
        marginTop: 0,
        marginLeft: 0,
        marginRight: 0,
        alignTicks: false,
        backgroundColor: 'transparent'
      },
      title: {
        text: ''
      },
      legend: {
        enabled: false,
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 150,
        y: 100,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend?.backgroundColor || '#FFFFFF'
      },
      xAxis: {
        categories: [
          'Gen',
          'Feb',
          'Mar',
          'Apr',
          'Mag',
          'Giu',
          'Lug'
        ],
        plotBands: [],
        labels: {
          style: {
            color: document.documentElement.style.getPropertyValue("--primaryText")
          }
        }
      },
      yAxis: {
        // minTickInterval: 1,
        // tickAmount: 1,
        tickInterval: 1,
        visible: false,
        title: {
          text: ''
        }
      },
      tooltip: {
        shared: true,
        valueSuffix: ''
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.7,
        },
      },
      series: [{
        name: 'Post Schedulati',
        type: 'areaspline',
        data: [3, 4, 3, 5, 4, 7, 4],
        color: document.documentElement.style.getPropertyValue("--primaryColor")
      }, {
        name: 'Post Pubblicati',
        type: 'areaspline',
        data: [1, 3, 4, 3, 3, 5, 4],
        color: document.documentElement.style.getPropertyValue("--contrastColor")
      }]
    };
  }

  ngOnInit(): void {
    if (!this.socialPages?.length) {
      this.getFbAccounts();
    }
  }

  getFbAccounts() {
    this.fbService.getAccounts('vladgiurgila97@gmail.com').then((accounts: any) => {
      const socialPages: any[] = [];
      accounts.forEach((facebookPage: any) => {
        if (facebookPage?.instagram?.id) {
          const instagramPage = facebookPage.instagram;
          instagramPage.type = 'instagram';
          socialPages.push(facebookPage.instagram);
          delete facebookPage.instagram;
        }
        facebookPage.type = 'facebook';
        socialPages.push(facebookPage);
      });
      this.socialPages = socialPages;
    }, (error) => {
      const errorMsg = error.error;
      if (errorMsg.includes('Missing access code, please authorize facebook app')) {
        this.unauthorizedFB = true;
      }
    });
  }
  
  toggleSocialPage(socialpageIndex: number) {
    if (this.socialPages) {
      if (this.socialPages[socialpageIndex].toggled) {
        this.socialPages[socialpageIndex].toggled = false;
      } else {
        this.socialPages[socialpageIndex].toggled = true;
      }
    }
  }

  openIntegrationsPage() {
    this.openIntegrationsPageEmitter.emit();
  }
}
