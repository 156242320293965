import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FbApiService } from '../fb-api.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnChanges {

  @Input() notificationsTabActive: boolean;
  scheduledPosts: any;
  socialPages: any;
  socialIframeUrl: any;

  constructor(
    private fbApiService: FbApiService,
  ) {
    this.notificationsTabActive = false;
    this.fbApiService.getAccounts('vladgiurgila97@gmail.com').then((accounts: any) => {
      const socialPages: any[] = [];
      accounts.forEach((facebookPage: any, facebookPageIndex: number) => {
        if (facebookPage?.instagram?.id) {
          const instagramPage = Object.assign(facebookPage.instagram);
          instagramPage.type = 'instagram';
          socialPages.push(instagramPage);
          facebookPage.instagram = null;
        }
        facebookPage.type = 'facebook';
        socialPages.push(facebookPage);
        if (facebookPageIndex + 1 === accounts.length) {
          this.socialPages = socialPages;
        }
      });
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.notificationsTabActive) {
      this.fbApiService.getScheduledPosts('vladgiurgila97@gmail.com').then((scheduledPosts: any) => {
        
        scheduledPosts.forEach((scheduledPost: any, scheduledPostIndex: number) => {
          const account = this.socialPages.find((socialpage: any) => socialpage.id == scheduledPost.data.accountId);
          scheduledPosts[scheduledPostIndex].account = account;
        });
        
        scheduledPosts.sort((el1: any, el2: any) => 
          el1.timestamp < el2.timestamp ? 1 : (el2.timestamp < el1.timestamp ? -1 : 0)
        );
        this.scheduledPosts = scheduledPosts;
        console.log(scheduledPosts);
      });
    }
  }

  ngOnInit(): void {
  }

  openSocialPage(scheduledPost: any) {
    const username = scheduledPost.account.username;
    const platform = scheduledPost.account.type;
    this.socialIframeUrl = `https://${platform}.com/${username}`;
    window.open(this.socialIframeUrl, '_blank');
  }
}
