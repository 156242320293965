import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(
    private http: HttpClient,
  ) { }

  uploadFileAndGetUrl(file: File) {
    let formData:FormData = new FormData();
    formData.append('media', file, file.name);
    let headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    });

    return this.http.post(`https://ioposto.com/api/file/upload`, formData, { headers: headers}).toPromise();
  }
}
