import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface SchedulePostBody {
  timestamp: number,
  data: {
    message: string,
    mediaUrl?: string,
    accountEmail: string,
    accountId: string,
    postType: string,
    platform: string,
  }
}

@Injectable({
  providedIn: 'root'
})
export class FbApiService {

  fbAccounts: any;
  scheduledPosts: any;

  constructor(
    private http: HttpClient,
  ) { }

  // createPortfolio(portfolio: Portfolio) {
  //   return this.http.post(`${environment.serverAPI}/portfolio/create`, portfolio).toPromise();
  // }

  getAccounts(userEmail: string, forceRefresh: boolean = false) {
    return new Promise((res, rej) => {
      if (!this.fbAccounts || forceRefresh) {
        this.http.get(`https://ioposto.com/api/fb/${userEmail}/accounts`).toPromise().then((accounts) => {
          this.fbAccounts = accounts;
          res(JSON.parse(JSON.stringify(this.fbAccounts)));
        });
      } else {
        res(JSON.parse(JSON.stringify(this.fbAccounts)));
      }
    });
  }
  
  getScheduledPosts(userEmail: string, forceRefresh: boolean = false) {
    return new Promise((res, rej) => {
      if (!this.scheduledPosts || forceRefresh) {
        this.http.get(`https://ioposto.com/api/fb/schedules?email=${userEmail}`).toPromise().then((scheduledPosts) => {
          this.scheduledPosts = scheduledPosts;
          res(JSON.parse(JSON.stringify(this.scheduledPosts)));
        });
      } else {
        res(JSON.parse(JSON.stringify(this.scheduledPosts)));
      }
    });
  }

  schedulePost(data: SchedulePostBody) {
    return this.http.post('https://ioposto.com/api/fb/schedule', data).toPromise();
  }
}
