import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { CustomDateFormatter } from '../demo-utils/calendar-formatter.provider';
import { colors } from '../demo-utils/colors';
import { FbApiService } from '../fb-api.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
        provide: CalendarDateFormatter,
        useClass: CustomDateFormatter,
    },
  ]
})
export class CalendarComponent implements OnInit, OnChanges {

  @Input() view: CalendarView = CalendarView.Month;
  viewDate: Date;
  @Input() lastCalendarDate?: Date;
  viewDateplus1: Date;
  events: CalendarEvent[];
  refresh: Subject<void>;
  selectedFormattedMonth: string;
  selectedFormattedDay: string;
  selectedEventsDate: CalendarEvent[];
  scheduledPosts: any[];
  dayEvents: any;
  socialPages: any;
  @Output() calendarTypeEmitter: EventEmitter<string>;
  @Output() openPostEmitter: EventEmitter<any>;
  loading: boolean = true;

  constructor(
    private fbApiService: FbApiService,
  ) {
    this.calendarTypeEmitter = new EventEmitter<string>();
    this.openPostEmitter = new EventEmitter<any>();
    this.scheduledPosts = [];
    this.viewDate = new Date();
    this.selectedEventsDate = [];
    this.viewDateplus1 = new Date();
    this.events = [];
    this.refresh = new Subject<void>();
    this.selectedFormattedMonth = this.viewDate.toLocaleString('it', { month: 'long' }) + ' ' + this.viewDate.getFullYear();
    this.selectedFormattedDay = this.viewDate.toLocaleString('it', { 
      // weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric"
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.socialPages?.length) {
      this.getFbAccounts().then(() => {
        this.updateData();
      });
    } else {
      this.updateData();
    }
  }

  updateData() {
    if (this.lastCalendarDate) {
      this.viewDate = new Date(this.lastCalendarDate);
    }
    this.selectedFormattedMonth = this.viewDate.toLocaleString('it', { month: 'long' }) + ' ' + this.viewDate.getFullYear();
    this.selectedFormattedDay = this.viewDate.toLocaleString('it', { 
      // weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric"
    });
    this.viewDateplus1.setDate(this.viewDateplus1.getDate() + 1);

    this.fbApiService.getScheduledPosts('vladgiurgila97@gmail.com').then((scheduledPosts: any) => {
      // this.scheduledPosts = scheduledPosts;
      const newEvents: any[] = [];
      scheduledPosts.forEach((event: any) => {
          newEvents.push({
            start: new Date(event.timestamp * 1000),
            title: 'Post of 2 dec',
            color: event.status === 'failed' ? colors.red : colors.purple,
            cssClass: 'card',
            draggable: true,
            meta: event
          });
      });
      this.events = newEvents;
      if (this.lastCalendarDate) {
        this.dayEvents = this.events.filter((event) => 
          new Date(event.start).getMonth() === this.viewDate.getMonth() && 
          new Date(event.start).getDate() === this.viewDate.getDate()
        );
        this.dayEvents.forEach((dayEvent: any, dayEventIndex: number) => {
          const account = this.socialPages.find((socialpage: any) => socialpage.id == dayEvent.meta.data.accountId);
          this.dayEvents[dayEventIndex].account = account;
        });
        this.dayEvents = this.dayEvents.sort((el1: any, el2: any) => el1.meta.timestamp < el2.meta.timestamp ? -1 : (el1.meta.timestamp === el2.meta.timestamp ? 0 : 1));
      }
      this.loading = false;
      this.refresh.next();
    });
  }

  ngOnInit(): void {
  }

  getFbAccounts() {
    return new Promise((res, rej) => {
      this.fbApiService.getAccounts('vladgiurgila97@gmail.com').then((accounts: any) => {
        const socialPages: any[] = [];
        accounts.forEach((facebookPage: any, facebookPageIndex: number) => {
          if (facebookPage?.instagram?.id) {
            const instagramPage = Object.assign(facebookPage.instagram);
            instagramPage.type = 'instagram';
            socialPages.push(instagramPage);
            facebookPage.instagram = null;
          }
          facebookPage.type = 'facebook';
          socialPages.push(facebookPage);
          if (facebookPageIndex + 1 === accounts.length) {
            this.socialPages = socialPages;
            console.log(this.socialPages)
            res(true);
          }
        });
        
      }, (error) => {
        const errorMsg = error.error;
        rej(errorMsg);
        // if (errorMsg.includes('Missing access code, please authorize facebook app')) {
        //   this.unauthorizedFB = true;
        // }
      });
    });
  }

  changeCalendarView(direction: string) {
    if (direction === 'previous') {
      if (this.view === CalendarView.Month) {
        this.viewDate.setMonth(this.viewDate.getMonth() - 1);
        this.selectedFormattedMonth = this.viewDate.toLocaleString('it', { month: 'long' }) + ' ' + this.viewDate.getFullYear();
      } else if (this.view === CalendarView.Day) {
        this.viewDate.setDate(this.viewDate.getDate() - 1);
        this.selectedFormattedDay = this.viewDate.toLocaleString('it', { 
          // weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      }
    } else if (direction === 'next') {
      if (this.view === CalendarView.Month) {
        this.viewDate.setMonth(this.viewDate.getMonth() + 1);
        this.selectedFormattedMonth = this.viewDate.toLocaleString('it', { month: 'long' }) + ' ' + this.viewDate.getFullYear();
      } else if (this.view === CalendarView.Day) {
        this.viewDate.setDate(this.viewDate.getDate() + 1);
        this.selectedFormattedDay = this.viewDate.toLocaleString('it', { 
          // weekday: "long",
          year: "numeric",
          month: "short",
          day: "numeric"
        });
      }
    }
    this.dayEvents = this.events.filter((event) => 
      new Date(event.start).getMonth() === this.viewDate.getMonth() && 
      new Date(event.start).getDate() === this.viewDate.getDate()
    );
    this.dayEvents.forEach((dayEvent: any, dayEventIndex: number) => {
      const account = this.socialPages.find((socialpage: any) => socialpage.id == dayEvent.meta.data.accountId);
      this.dayEvents[dayEventIndex].account = account;
    });
    this.dayEvents = this.dayEvents.sort((el1: any, el2: any) => el1.meta.timestamp < el2.meta.timestamp ? -1 : (el1.meta.timestamp === el2.meta.timestamp ? 0 : 1));
    this.refresh.next();
  }

  closeDay() {
    this.view = CalendarView.Month;
  }

  refreshSelectedDaysEvent(clickedDate: Date) {
    const selectedDaysEvent: any[] = this.events.filter((el: any) => {
      return el.start.getDate() == clickedDate.getDate() &&
        el.start.getMonth() == clickedDate.getMonth() &&
        el.start.getFullYear() == clickedDate.getFullYear()
    });
    this.view = CalendarView.Day;
    this.calendarTypeEmitter.emit('calendar-day');
    if (selectedDaysEvent?.[0]?.start) {
      this.viewDate = new Date(selectedDaysEvent[0].start);
      this.dayEvents = this.events.filter((event) => 
        new Date(event.start).getMonth() === this.viewDate.getMonth() && 
        new Date(event.start).getDate() === this.viewDate.getDate()
      );
      this.dayEvents.forEach((dayEvent: any, dayEventIndex: number) => {
        const account = this.socialPages.find((socialpage: any) => socialpage.id == dayEvent.meta.data.accountId);
        this.dayEvents[dayEventIndex].account = account;
      });
      this.dayEvents = this.dayEvents.sort((el1: any, el2: any) => el1.meta.timestamp < el2.meta.timestamp ? -1 : (el1.meta.timestamp === el2.meta.timestamp ? 0 : 1));
    } else {
      this.viewDate = new Date(clickedDate);
      this.dayEvents = [];
    }
    this.selectedFormattedDay = this.viewDate.toLocaleString('it', { 
      // weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric"
    });
    this.selectedEventsDate = selectedDaysEvent;
  }

  openDay(date: any) {
    const clickedDate = new Date(date);
    this.lastCalendarDate = date;
    this.refreshSelectedDaysEvent(clickedDate);
    // this.calendarTypeEmitter.emit(CalendarView.Day);
    this.refresh.next();
  }

  openNewPost() {
    this.openPostEmitter.emit({start: this.lastCalendarDate});
    // this.lastCalendarDate = new Date();
  }
  
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.refresh.next();
  }

}
