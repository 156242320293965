import { FbApiService } from './fb-api.service';
import { Component } from '@angular/core';
import { CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'notion-addon-popup';
  loggedIn: boolean = false;
  notificationsTabActive: boolean = false;
  menuTabActive: boolean = false;
  currentPage: string = 'login';
  socialPages?: any[];
  CalendarViewEnum: any = CalendarView;
  selectedPost: any;
  lastCalendarDate?: Date;

  constructor(
    private fbApiService: FbApiService,
  ) {
    this.init();
    // this.currentPage = 'calendar';
  }

  loginSucceeded(event: boolean) {
    this.loggedIn = event;
    localStorage.setItem('loggedIn', JSON.stringify(this.loggedIn));
    if (this.loggedIn) {
      this.currentPage = 'calendar';
    }
  }

  openPostPreview() {
    if (this.selectedPost.account.type === 'instagram' && !this.selectedPost.meta.data.mediaUrl) {
      alert('Contenuto multimediale necessario per Instagram');
    } else {
      this.currentPage = 'schedule-post-preview';
    }
  }

  openPost(post: any) {
    this.lastCalendarDate = post.start;
    this.selectedPost = post;
    this.currentPage = 'schedule-post';
  }

  redirectToWebsite() {
    window.open('https://www.notion.so/', '_blank')
  }

  openSocialPages(socialPages: any[]) {
    this.socialPages = socialPages;
    this.currentPage = 'social-pages';
  }

  toggleNotificationsTab() {
    this.menuTabActive = false;
    this.notificationsTabActive = !this.notificationsTabActive;
  }

  toggleMenuTab() {
    this.notificationsTabActive = false;
    this.menuTabActive = !this.menuTabActive;
  }

  changeActivePage(page: string) {
    this.currentPage = page;
    this.menuTabActive = false;
  }

  closePreviewAndSelectDay(post: any) {
    this.fbApiService.getScheduledPosts('vladgiurgila97@gmail.com', true).then(() => {
      this.currentPage = 'calendar-day';
      this.lastCalendarDate = new Date(this.selectedPost.meta.timestamp * 1000);
    })
  }

  init() {
    this.loggedIn = false;
    this.currentPage = 'login';
    this.notificationsTabActive = false;
    this.menuTabActive = false;

    const loggedIn =  localStorage.getItem('loggedIn');
    if (loggedIn) {
      this.loggedIn = JSON.parse(loggedIn);
      this.loginSucceeded(this.loggedIn);
    }
  }
}
