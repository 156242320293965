import { FbApiService } from './../fb-api.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss']
})
export class UserSelectComponent implements OnInit {

  socialPages: any;
  @Input() selectedAccount: any;
  @Input() editable: boolean = true;
  expandedAccounts: boolean = false;
  @Output() selectedAccountEmitter: EventEmitter<any>;

  constructor(
    private fbApiService: FbApiService,
  ) {
    this.selectedAccountEmitter = new EventEmitter<any>();
    this.fbApiService.getAccounts('vladgiurgila97@gmail.com').then((accounts: any) => {
      const socialPages: any[] = [];
      accounts.forEach((facebookPage: any, facebookPageIndex: number) => {
        if (facebookPage?.instagram?.id) {
          const instagramPage = Object.assign(facebookPage.instagram);
          instagramPage.type = 'instagram';
          socialPages.push(instagramPage);
          facebookPage.instagram = null;
        }
        facebookPage.type = 'facebook';
        socialPages.push(facebookPage);
        if (facebookPageIndex + 1 === accounts.length) {
          this.socialPages = socialPages;
        }
      });
    });
  }

  ngOnInit(): void {
  }

  toggleExpandAccounts() {
    this.expandedAccounts = !this.expandedAccounts;
  }

  selectAccount(account: any) {
    this.selectedAccount = account;
    this.expandedAccounts = false;
    this.selectedAccountEmitter.emit(this.selectedAccount);
  }

}
