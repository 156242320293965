<div class="page-content">

    <div *ngIf="unauthorizedFB" class="error appear-animation">
        <div class="error-message">
            Integrazione Facebook Business Manager mancante.
        </div>
        <div class="error-cta button" (click)="openIntegrationsPage()">
            Aggiungi integrazione
        </div>
    </div>

    <ng-container *ngIf="socialPages?.length && !unauthorizedFB">
        <ng-container *ngFor="let socialPage of socialPages; let socialPageIndex = index;" #socialPageElement>
            <div class="integration-element appear-animation" (click)="toggleSocialPage(socialPageIndex)">
                <div class="integration-element--logo">
                    <!-- avatar icon -->
                    <img class="integration-element--logo-icon" [attr.src]="socialPage.picture" />
                    <!-- fb icon -->
                    <svg *ngIf="socialPage.type === 'facebook'" class="integration-element--logo-mini-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M34.375 4.375H5.625C4.93359 4.375 4.375 4.93359 4.375 5.625V34.375C4.375 35.0664 4.93359 35.625 5.625 35.625H34.375C35.0664 35.625 35.625 35.0664 35.625 34.375V5.625C35.625 4.93359 35.0664 4.375 34.375 4.375ZM30.7656 13.4961H28.2695C26.3125 13.4961 25.9336 14.4258 25.9336 15.793V18.8047H30.6055L29.9961 23.5195H25.9336V35.625H21.0625V23.5234H16.9883V18.8047H21.0625V15.3281C21.0625 11.293 23.5273 9.09375 27.1289 9.09375C28.8555 9.09375 30.3359 9.22266 30.7695 9.28125V13.4961H30.7656Z" fill="#4267B2"/>
                    </svg>
                    <app-icon icon="instagram" *ngIf="socialPage.type === 'instagram'" class="integration-element--logo-mini-icon" style="bottom: 0;"></app-icon>
                </div>
                <div class="integration-element--paragraph">
                    <div class="integration-element--paragraph-title">{{socialPage.name}}</div>
                    <div class="integration-element--paragraph-text">@{{socialPage.username}}</div>
                    <div class="integration-element--paragraph-text"><span style="text-transform: capitalize;">{{socialPage.type}}</span></div>
                </div>
                <div class="integration-element--cta">
                    <!-- up arrow icon -->
                    <svg class="integration-element--cta-icon" [ngClass]="{'integration-element--cta-icon-rotated': socialPage.toggled}" width="40" height="40" viewBox="0 0 40 40" style="fill: none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.3333 25L20 11.6667L6.66665 25" stroke="black" stroke-opacity="0.6" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>

            <div class="integration-element integration-element-expansion appear-animation" *ngIf="socialPage.toggled">
                <div class="page-counters">
                    <div class="counter">
                        <div class="counter-value color-primary">42</div>
                        <div class="counter-label">Post schedulati</div>
                    </div>
                    <div class="counter">
                        <div class="counter-value color-secondary">37</div>
                        <div class="counter-label">Post pubblicati</div>
                    </div>
                </div>
            
                <highcharts-chart 
                    [Highcharts]="Highcharts"
                    [options]="chartOptions"
                    style="width: 100%; height: 200px; display: block;"
                ></highcharts-chart>
            
                <div class="button">Calendario Editoriale</div>
            </div>

        </ng-container>

        <div class="page-footer appear-animation">
            <div class="page-footer--help">Non trovi la tua pagina? <span class="link">Aggiorna l'accesso</span></div>
        </div>
        
    </ng-container>

</div>