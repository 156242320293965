<div [ngSwitch]="view" *ngIf="!loading">
    <!-- <app-user-select [selectedAccount]="selectedPost.account" (selectedAccountEmitter)="updateSelectedAccount($event)" [editable]="true"></app-user-select> -->
    <div class="calendar-controls appear-animation">
        <div class="calendar-control" (click)="changeCalendarView('previous')">
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.3333 16.6667L6.66668 10.0001L13.3333 3.33342" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.3333 16.6667L6.66668 10.0001L13.3333 3.33342" fill="none" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="calendar-title">
            {{ view === 'month' ? selectedFormattedMonth : ''}}
            {{ view === 'day' ? selectedFormattedDay : ''}}
        </div>
        <div class="calendar-control" (click)="changeCalendarView('next')">
            <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform: rotate(180deg);">
                <path d="M13.3333 16.6667L6.66668 10.0001L13.3333 3.33342" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.3333 16.6667L6.66668 10.0001L13.3333 3.33342" fill="none" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
    <ng-container *ngSwitchCase="'month'">
        <div class="appear-animation">
            <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [activeDayIsOpen]="false"
                [refresh]="refresh" [weekStartsOn]="1" (eventTimesChanged)="eventTimesChanged($event)"
                (dayClicked)="openDay($event.day.date)">
            </mwl-calendar-month-view>
        </div>
    </ng-container>
    <!-- <mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
        (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view> -->
    <!-- <mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [events]="dayEvents" [refresh]="refresh"
        (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view> -->
    <ng-container *ngSwitchCase="'day'">
        <div class="appear-animation">
    
            <div class="integration-element" *ngIf="!dayEvents?.length">
                <div class="integration-element--paragraph" style="max-width: 100%;">
                    <div class="integration-element--paragraph-text">
                        Nessun post schedulato per questa giornata
                    </div>
                </div>
            </div>
            <ng-container *ngFor="let dayEvent of dayEvents">
                <div>
                    
        
                    <!-- <div>{{dayEvent.account.type}}</div> -->
                    <!-- <div>{{dayEvent.meta.data.message}}</div>
                    <div>{{dayEvent.account.username || dayEvent.account.name }}</div> -->
                    <!-- <div>{{dayEvent.start | date: 'HH:mm'}}</div>
                    <div>{{dayEvent.meta.status}}</div> -->


                    <div class="integration-element" [ngClass]="{
                        'integration-element--failed': dayEvent.meta.status === 'failed',
                        'integration-element--success': dayEvent.meta.status === 'posted'
                    }" (click)="openPostEmitter.emit(dayEvent)">
                        <div class="integration-element--logo">
                            <!-- avatar icon -->
                            <ng-container *ngIf="dayEvent.meta.data.postType === 'video'">
                                <video class="integration-element--logo-icon" autoplay muted playsinline loop [attr.src]="dayEvent.meta.data.mediaUrl | sanitizeUrl"></video>
                            </ng-container>
                            <ng-container *ngIf="dayEvent.meta.data.postType === 'photo'">
                                <img class="integration-element--logo-icon" [attr.src]="dayEvent.meta.data.mediaUrl | sanitizeUrl" />
                            </ng-container>
                            <!-- fb icon -->
                            <svg *ngIf="dayEvent.meta.data.platform === 'facebook'" class="integration-element--logo-mini-icon" style="bottom: -8px;" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M34.375 4.375H5.625C4.93359 4.375 4.375 4.93359 4.375 5.625V34.375C4.375 35.0664 4.93359 35.625 5.625 35.625H34.375C35.0664 35.625 35.625 35.0664 35.625 34.375V5.625C35.625 4.93359 35.0664 4.375 34.375 4.375ZM30.7656 13.4961H28.2695C26.3125 13.4961 25.9336 14.4258 25.9336 15.793V18.8047H30.6055L29.9961 23.5195H25.9336V35.625H21.0625V23.5234H16.9883V18.8047H21.0625V15.3281C21.0625 11.293 23.5273 9.09375 27.1289 9.09375C28.8555 9.09375 30.3359 9.22266 30.7695 9.28125V13.4961H30.7656Z" fill="#4267B2"/>
                            </svg>
                            <!-- instagram icon -->
                            <app-icon icon="instagram" *ngIf="dayEvent.meta.data.platform === 'instagram'" class="integration-element--logo-mini-icon"></app-icon>
                        </div>
                        <div class="integration-element--paragraph">
                            <div class="integration-element--paragraph-title">{{dayEvent.account?.name || '@' + dayEvent.account?.username }}</div>
                            <div class="integration-element--paragraph-text">{{dayEvent.meta.data.message}}</div>
                            <!-- <div class="integration-element--paragraph-subtitle">Modifica</div> -->
                        </div>
                        <div class="integration-element--status">
                            <!-- up arrow icon -->
                            <!-- <svg class="integration-element--status-icon" [ngClass]="{'integration-element--status-icon-rotated': false}" width="40" height="40" viewBox="0 0 40 40" style="fill: none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M33.3333 25L20 11.6667L6.66665 25" stroke="black" stroke-opacity="0.6" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg> -->
                            <!-- <svg class="integration-element--status-icon"  width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 0 25.3043 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0ZM17.5086 23.9457L13.0657 19.5C12.9064 19.3407 12.7174 19.2144 12.5093 19.1282C12.3011 19.042 12.0781 18.9976 11.8529 18.9976C11.6276 18.9976 11.4046 19.042 11.1965 19.1282C10.9884 19.2144 10.7993 19.3407 10.64 19.5C10.3183 19.8217 10.1376 20.2579 10.1376 20.7129C10.1376 21.1678 10.3183 21.604 10.64 21.9257L16.2971 27.5829C16.456 27.7429 16.6449 27.87 16.8531 27.9567C17.0612 28.0434 17.2845 28.088 17.51 28.088C17.7355 28.088 17.9588 28.0434 18.1669 27.9567C18.3751 27.87 18.564 27.7429 18.7229 27.5829L30.4371 15.8657C30.5985 15.7071 30.7269 15.5181 30.8149 15.3096C30.9029 15.1011 30.9488 14.8773 30.9498 14.651C30.9509 14.4247 30.9071 14.2004 30.8211 13.9911C30.735 13.7818 30.6084 13.5917 30.4485 13.4315C30.2885 13.2714 30.0985 13.1446 29.8893 13.0583C29.6801 12.972 29.4559 12.928 29.2296 12.9287C29.0033 12.9295 28.7794 12.9751 28.5708 13.0629C28.3622 13.1506 28.1731 13.2788 28.0143 13.44L17.5086 23.9457Z" fill="#00BA07" fill-opacity="0.6"/>
                            </svg> -->

                            <svg *ngIf="dayEvent.meta.status === 'posted'" class="integration-element--status-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 0C25.3043 0 30.3914 2.10714 34.1421 5.85786C37.8929 9.60859 40 14.6957 40 20C40 25.3043 37.8929 30.3914 34.1421 34.1421C30.3914 37.8929 25.3043 40 20 40C14.6957 40 9.60859 37.8929 5.85786 34.1421C2.10714 30.3914 0 25.3043 0 20C0 14.6957 2.10714 9.60859 5.85786 5.85786C9.60859 2.10714 14.6957 0 20 0ZM17.5086 23.9457L13.0657 19.5C12.9064 19.3407 12.7174 19.2144 12.5093 19.1282C12.3011 19.042 12.0781 18.9976 11.8529 18.9976C11.6276 18.9976 11.4046 19.042 11.1965 19.1282C10.9884 19.2144 10.7993 19.3407 10.64 19.5C10.3183 19.8217 10.1376 20.2579 10.1376 20.7129C10.1376 21.1678 10.3183 21.604 10.64 21.9257L16.2971 27.5829C16.456 27.7429 16.6449 27.87 16.8531 27.9567C17.0612 28.0434 17.2845 28.088 17.51 28.088C17.7355 28.088 17.9588 28.0434 18.1669 27.9567C18.3751 27.87 18.564 27.7429 18.7229 27.5829L30.4371 15.8657C30.5985 15.7071 30.7269 15.5181 30.8149 15.3096C30.9029 15.1011 30.9488 14.8773 30.9498 14.651C30.9509 14.4247 30.9071 14.2004 30.8211 13.9911C30.735 13.7818 30.6084 13.5917 30.4485 13.4315C30.2885 13.2714 30.0985 13.1446 29.8893 13.0583C29.6801 12.972 29.4559 12.928 29.2296 12.9287C29.0033 12.9295 28.7794 12.9751 28.5708 13.0629C28.3622 13.1506 28.1731 13.2788 28.0143 13.44L17.5086 23.9457Z" fill="#00BA07" fill-opacity="0.6" stroke="none"/>
                            </svg>

                            <svg *ngIf="dayEvent.meta.status === 'pending'" class="integration-element--status-icon" height="40" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="white"/>
                                <path d="M14 15L11.586 12.586C11.2109 12.211 11.0001 11.7024 11 11.172V5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                                
                            <svg *ngIf="dayEvent.meta.status === 'failed'" class="integration-element--status-icon" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M29.414 0.586002C29.2286 0.399917 29.0082 0.252341 28.7655 0.151771C28.5229 0.0512003 28.2627 -0.000378898 28 2.09535e-06H12C11.7373 -0.000378898 11.4771 0.0512003 11.2345 0.151771C10.9918 0.252341 10.7714 0.399917 10.586 0.586002L0.586002 10.586C0.399917 10.7714 0.252341 10.9918 0.151771 11.2345C0.0512003 11.4771 -0.000378898 11.7373 2.09535e-06 12V28C2.09535e-06 28.532 0.210002 29.04 0.586002 29.414L10.586 39.414C10.7714 39.6001 10.9918 39.7477 11.2345 39.8482C11.4771 39.9488 11.7373 40.0004 12 40H28C28.532 40 29.04 39.79 29.414 39.414L39.414 29.414C39.6001 29.2286 39.7477 29.0082 39.8482 28.7655C39.9488 28.5229 40.0004 28.2627 40 28V12C40.0004 11.7373 39.9488 11.4771 39.8482 11.2345C39.7477 10.9918 39.6001 10.7714 39.414 10.586L29.414 0.586002ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z" fill="#FF0000" fill-opacity="0.6" stroke="none"/>
                            </svg>
                                

                            <!-- <div class="integration-element--status-icon">{{dayEvent.start | date: 'HH:mm'}}</div> -->
                                
                            <div class="integration-element--status-text">{{dayEvent.start | date: 'HH:mm'}}</div>
                        </div>
                    </div>


                </div>
            </ng-container>
            <div class="button" (click)="openNewPost()">Nuovo post</div>
        </div>
    </ng-container>
    
    
</div>