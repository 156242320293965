import { FileuploadService } from './../fileupload.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-schedule-post',
  templateUrl: './schedule-post.component.html',
  styleUrls: ['./schedule-post.component.scss']
})
export class SchedulePostComponent implements OnInit {

  @Input() selectedPost: any;
  @Output() openPreviewEmitter: EventEmitter<boolean>;
  @Output() selectedPostEmitter: EventEmitter<any>;
  @ViewChild("fileInput") fileInput?: ElementRef;
  emojiPopupToggled: boolean = false;
  fileLoaderActive: boolean = false;

  constructor(
    private fileUploadService: FileuploadService,
  ) {
    this.openPreviewEmitter = new EventEmitter<boolean>();
    this.selectedPostEmitter = new EventEmitter<boolean>();
  }

  ngOnInit(): void {
    if (!this.selectedPost?.account) {
      this.selectedPost = {
        account: {
          type: 'facebook',
        },
        meta: {
          data: {
            postType: 'photo',
            message: '',
            accountEmail: 'vladgiurgila97@gmail.com',
          },
          timestamp: new Date().getTime() / 1000,
        },
        start: new Date(),
      }
      this.emitSelectedPost();
    }
  }

  openFileUploadModal() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  toggleEmojiPopup() {
    this.emojiPopupToggled = !this.emojiPopupToggled;
  }

  updateFile(event: any) {
    const file = event.target.files?.[0];
    const fileName = file.name;
    const fileType = file.type;
    // fileName = e[0];
    // fileType = fileName.type;
    console.log(file, fileName, fileType);
    this.fileLoaderActive = true;
    this.fileUploadService.uploadFileAndGetUrl(file).then((fileObject: any) => {
      console.log(fileObject);
      if (fileObject.mimeType.includes('video')) {
        this.selectedPost.meta.data.mediaUrl = fileObject.fileUrl;
        this.selectedPost.meta.data.postType = 'video';
      } else if (fileObject.mimeType.includes('image')) {
        this.selectedPost.meta.data.mediaUrl = fileObject.fileUrl;
        this.selectedPost.meta.data.postType = 'photo';
      } else {
        alert('Invalid file');
      }
      this.fileLoaderActive = false;
    });
  }

  addEmoji(event: any) {
    console.log(event);
    this.selectedPost.meta.data.message += event.emoji.native;
    this.toggleEmojiPopup();
  }

  updateSelectedAccount(account: any) {
    this.selectedPost.account = account;
    // this.selectedPost.data.platform = account.type;
    // this.selectedPost.data.accountId = account.id;
  }

  emitSelectedPost() {
    this.selectedPostEmitter.emit(this.selectedPost);
  }

  updatePostDate(date: any) {
    this.selectedPost.start = new Date(date.substr(0,4), parseInt(date.substr(5,2)) - 1, date.substr(8,2), this.selectedPost.start.getHours(), this.selectedPost.start.getMinutes());
    this.selectedPost.meta.timestamp = this.selectedPost.start.getTime() / 1000;
    this.emitSelectedPost();
  }

  updatePostTime(time: any) {
    this.selectedPost.start = new Date(this.selectedPost.start.getFullYear(), this.selectedPost.start.getMonth(), this.selectedPost.start.getDate(), time.substr(0,2), time.substr(3,5));
    this.selectedPost.meta.timestamp = this.selectedPost.start.getTime() / 1000;
    this.emitSelectedPost();
  }

  openPreview() {
    if (!this.fileLoaderActive) {
      this.openPreviewEmitter.emit(true);
    } else {
      alert('Attendi il caricamento del file');
    }
  }

  changePostType(postType: string) {
    if (postType !== this.selectedPost.meta.data.postType) {
      this.selectedPost.meta.data.postType = postType;
      this.selectedPost.meta.data.mediaUrl = null;
      this.emitSelectedPost();
    }
  }

}
