import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { HttpClientModule } from '@angular/common/http';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { SocialPagesComponent } from './social-pages/social-pages.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { NotificationsComponent } from './notifications/notifications.component';
import { MenuComponent } from './menu/menu.component';
import { PaymentsComponent } from './payments/payments.component';
import { SettingsComponent } from './settings/settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarNativeDateFormatter, DateFormatterParams, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarComponent } from './calendar/calendar.component';
import { SchedulePostComponent } from './schedule-post/schedule-post.component';
import { SchedulePostPreviewComponent } from './schedule-post-preview/schedule-post-preview.component';
import { FormsModule } from '@angular/forms';
import { DemoUtilsModule } from './demo-utils/module';
import { UserSelectComponent } from './user-select/user-select.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { IconComponent } from './icon/icon.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    IntegrationsComponent,
    SanitizeUrlPipe,
    SocialPagesComponent,
    NotificationsComponent,
    MenuComponent,
    PaymentsComponent,
    SettingsComponent,
    CalendarComponent,
    SchedulePostComponent,
    SchedulePostPreviewComponent,
    UserSelectComponent,
    IconComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    BrowserAnimationsModule,
    FormsModule,
    PickerModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    DemoUtilsModule,
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
