import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  
})
export class SettingsComponent implements OnInit {

  @Output() loggedOutEmitter = new EventEmitter<null>();

  colors: any;
  
  constructor() {
    this.colors = {
      primaryColor: document.documentElement.style.getPropertyValue("--primaryColor"),
      contrastColor: document.documentElement.style.getPropertyValue("--contrastColor"),
      backgroundColor: document.documentElement.style.getPropertyValue("--backgroundColor"),
      darkText: document.documentElement.style.getPropertyValue("--darkText"),
      primaryText: document.documentElement.style.getPropertyValue("--primaryText"),
      secondaryText: document.documentElement.style.getPropertyValue("--secondaryText"),
      tertiaryText: document.documentElement.style.getPropertyValue("--tertiaryText"),
      lightText: document.documentElement.style.getPropertyValue("--lightText"),
    }
  }

  ngOnInit(): void {
  }

  updateColor(label: string, color: string) {
    this.colors[label] = color;
    document.documentElement.style.setProperty(`--${label}`, color);
    localStorage.setItem('colors', JSON.stringify(this.colors));
  }

  logout() {
    localStorage.removeItem('loggedIn');
    this.loggedOutEmitter.emit();
  }
}
