import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
let pageWidth = "300px";
let pageHeight = "520px";
if (environment.isWebApp) {
  pageWidth = "initial";
  pageHeight = "initial";
}
document.documentElement.style.setProperty("--pageWidth", pageWidth);
document.documentElement.style.setProperty("--pageHeight", pageHeight);

const colors = JSON.parse(localStorage.getItem('colors') as string);

// document.documentElement.style.setProperty("--primaryColor" , colors?.primaryColor || "#c04eff");
// document.documentElement.style.setProperty("--contrastColor", colors?.contrastColor || "#341c54");

document.documentElement.style.setProperty("--primaryColor" , colors?.primaryColor || "#EB5E55");
document.documentElement.style.setProperty("--contrastColor", colors?.contrastColor || "#3A3335");

// document.documentElement.style.setProperty("--primaryColor", "#0073bb");
// document.documentElement.style.setProperty("--contrastColor", "#232f3e");

// document.documentElement.style.setProperty("--primaryColor", "#928C6F");
// document.documentElement.style.setProperty("--contrastColor", "#928C6F");

document.documentElement.style.setProperty("--backgroundColor", colors?.backgroundColor || "#fff");
document.documentElement.style.setProperty("--darkText", colors?.darkText || "rgba(0,0,0,0.8)");
document.documentElement.style.setProperty("--primaryText", colors?.primaryText || "rgba(0,0,0,0.6)");
document.documentElement.style.setProperty("--secondaryText", colors?.secondaryText || "rgba(0,0,0,0.2)");
document.documentElement.style.setProperty("--tertiaryText", colors?.tertiaryText || "rgba(0,0,0,0.1)");
document.documentElement.style.setProperty("--lightText", colors?.lightText || "rgba(0,0,0,0.05)");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));