import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";
import { CalendarDateFormatter, DateFormatterParams } from "angular-calendar";

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
    // you can override any of the methods defined in the parent class

    private getLang() {
        if (navigator.languages != undefined)
            return navigator.languages[0];
        return navigator.language;
    }

    private capitalize(s: string) {
        return s && s[0].toUpperCase() + s.slice(1);
    }

    public monthViewColumnHeader({ date, locale = this.getLang() }: DateFormatterParams): string {
        return this.capitalize(formatDate(date, 'EEE', this.getLang())).substring(0, 3);
    }

    public monthViewTitle({ date, locale = this.getLang() }: DateFormatterParams): string {
        return this.capitalize(formatDate(date, 'MMM y', this.getLang()));
    }

    public weekViewColumnHeader({ date, locale = this.getLang() }: DateFormatterParams): string {
        return this.capitalize(formatDate(date, 'EEE', this.getLang()));
    }

    public dayViewHour({ date, locale = this.getLang() }: DateFormatterParams): string {
        return this.capitalize(formatDate(date, 'HH:mm', this.getLang()));
    }
}