export const colors: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    purple: {
        primary: document.documentElement.style.getPropertyValue("--primaryColor"),
        secondary: document.documentElement.style.getPropertyValue("--contrastColor"),
    }
  };
  