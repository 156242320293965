import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  
  @Output() loggedInEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  login() {
    this.loggedInEmitter.emit(true);
  }

  changeBackgroundColor() {
    chrome.tabs.query({ active: true, currentWindow: true }, tabs => {
      chrome.tabs.executeScript(
        tabs[0].id!,
        { code: `document.body.style.background = '${ 'red' }';` }
      );
    });

    chrome.storage.sync.get('color', ({ color }) => {
      alert(color);
    });
    chrome.storage.sync.set({ color: '#3aa757' });
  }

}
