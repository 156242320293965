<input type="color" [ngModel]="colors.backgroundColor" (ngModelChange)="updateColor('backgroundColor', $event)"/>
<div>backgroundColor: {{colors.backgroundColor}}</div>

<input type="color" [ngModel]="colors.primaryColor" (ngModelChange)="updateColor('primaryColor', $event)"/>
<div>primaryColor: {{colors.primaryColor}}</div>
 
<input type="color" [ngModel]="colors.contrastColor" (ngModelChange)="updateColor('contrastColor', $event)"/>
<div>contrastColor: {{colors.contrastColor}}</div>
 
<input type="color" [ngModel]="colors.darkText" (ngModelChange)="updateColor('darkText', $event)"/>
<div>darkText: {{colors.darkText}}</div>
 
<input type="color" [ngModel]="colors.primaryText" (ngModelChange)="updateColor('primaryText', $event)"/>
<div>primaryText: {{colors.primaryText}}</div>
 
<input type="color" [ngModel]="colors.secondaryText" (ngModelChange)="updateColor('secondaryText', $event)"/>
<div>secondaryText: {{colors.secondaryText}}</div>
 
<input type="color" [ngModel]="colors.tertiaryText" (ngModelChange)="updateColor('tertiaryText', $event)"/>
<div>tertiaryText: {{colors.tertiaryText}}</div>
 
<input type="color" [ngModel]="colors.lightText" (ngModelChange)="updateColor('lightText', $event)"/>
<div>lightText: {{colors.lightText}}</div>
 
<div class="button appear-animation" (click)="logout()">Logout</div>